$color-orange: rgb(240, 125, 39);

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.6;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
}

.btn-singularit {
  background: $color-orange;
  color: #fff;
  font-weight: 700;
}

.btn-singularit:hover {
  background-color: #d5640f;
  transition-duration: 0.5s;
  color: #fff;
}
body:hover {
  animation: rotate 1000s linear 0s infinite;
}
.btn-outline-singularit {
  color: $color-orange;
  background-color: #fff;
  font-weight: 700;
  border: 3px solid $color-orange;
  display: inline;
}

.btn-outline-singularit:hover {
  color: #d5640f;
  border-color: #d5640f;
  transition-duration: 0.5s;
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
  font-family: Raleway thin, sans-serif;
  font-weight: 700;
  line-height: 150%;
}

h1,
h2 {
  font-size: 2.6rem;
  letter-spacing: 0.14rem;
  margin-bottom: 2rem;
  font-weight: 100;

  @media (max-width: 800px) {
    font-size: 1.8em;
  }
}

h3 {
  font-size: 2rem;
  font-weight: 100;
}

p {
  line-height: 160%;
  font-size: 1.2em;
}

.container {
  padding-right: 35px;
  padding-left: 35px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
}
