.app-content {
  display: grid;
  min-height: 100vh;
  max-width: 100vw;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;

  .input-area {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto 1fr auto;
    column-gap: 12px;
    grid-template-areas: 
      "form . description"
      ". . description"
      "button . description";
    overflow-y: hidden;
    transition: max-height 0.3s ease-in-out;

    &.hidden {
      max-height: 0px;
      transition: max-height 0.3s ease-in-out;
    }
    form{
      grid-area: form;
    }
    .usage-description{
      grid-area: description;
    }
    .contact{
      grid-area: button;
      max-width: 80px;
    }
    @media screen and (max-width: 845px) {
      grid-template-columns: 1fr ;
      grid-template-rows: auto auto auto;
      column-gap: 0;
      grid-template-areas: 
        "description"
        "form"
        "button";
        row-gap: 24px;
      
    }
  }

  .extras-wrapper {
    margin-top: 32px;
    text-align: center;

    code {
      color: red;
    }

    .control-buttons {
      margin-bottom: 32px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 32px;
      justify-content: center;
    }
  }
}
