@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400;600&display=swap);
.btn{display:inline-block;margin-bottom:0;font-weight:400;text-align:center;white-space:nowrap;vertical-align:middle;touch-action:manipulation;cursor:pointer;background-image:none;border:1px solid transparent;padding:6px 12px;font-size:14px;line-height:1.6;border-radius:4px;-webkit-user-select:none;-ms-user-select:none;user-select:none;text-decoration:none}.btn-singularit{background:#f07d27;color:#fff;font-weight:700}.btn-singularit:hover{background-color:#d5640f;transition-duration:0.5s;color:#fff}body:hover{-webkit-animation:rotate 1000s linear 0s infinite;animation:rotate 1000s linear 0s infinite}.btn-outline-singularit{color:#f07d27;background-color:#fff;font-weight:700;border:3px solid #f07d27;display:inline}.btn-outline-singularit:hover{color:#d5640f;border-color:#d5640f;transition-duration:0.5s;text-decoration:underline}h1,h2,h3,h4,h5,h6{color:#000;font-family:Raleway thin, sans-serif;font-weight:700;line-height:150%}h1,h2{font-size:2.6rem;letter-spacing:0.14rem;margin-bottom:2rem;font-weight:100}@media (max-width: 800px){h1,h2{font-size:1.8em}}h3{font-size:2rem;font-weight:100}p{line-height:160%;font-size:1.2em}.container{padding-right:35px;padding-left:35px;margin-right:auto;margin-left:auto;max-width:1170px}body{margin:0;font-family:Raleway, sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;box-sizing:border-box}code{font-family:source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace}.divider{border-bottom:1px solid #eee}a{color:inherit}

.app-content{display:grid;min-height:100vh;max-width:100vw;grid-template-columns:1fr;grid-template-rows:auto 1fr auto}.app-content .input-area{display:grid;grid-template-columns:auto 1fr auto;grid-template-rows:auto 1fr auto;grid-column-gap:12px;-webkit-column-gap:12px;column-gap:12px;grid-template-areas:"form . description" ". . description" "button . description";overflow-y:hidden;transition:max-height 0.3s ease-in-out}.app-content .input-area.hidden{max-height:0px;transition:max-height 0.3s ease-in-out}.app-content .input-area form{grid-area:form}.app-content .input-area .usage-description{grid-area:description}.app-content .input-area .contact{grid-area:button;max-width:80px}@media screen and (max-width: 845px){.app-content .input-area{grid-template-columns:1fr;grid-template-rows:auto auto auto;grid-column-gap:0;-webkit-column-gap:0;column-gap:0;grid-template-areas:"description" "form" "button";grid-row-gap:24px;row-gap:24px}}.app-content .extras-wrapper{margin-top:32px;text-align:center}.app-content .extras-wrapper code{color:red}.app-content .extras-wrapper .control-buttons{margin-bottom:32px;display:flex;flex-direction:row;flex-wrap:wrap;grid-column-gap:32px;-webkit-column-gap:32px;column-gap:32px;justify-content:center}

.header{height:84.4px}#logo{width:180px;margin-top:23px;vertical-align:middle}

form{width:300px;margin-top:32px}form #size-slider{font-size:1.2em}form #size-slider .size-value{font-weight:bold}form .MuiTypography-body1{font-family:inherit}form .element{margin-bottom:16px}form .submit-btn{margin-top:32px}

.usage-description{max-width:600px}.usage-description p{margin-bottom:0}

.image-area{display:flex;flex-wrap:wrap;flex-direction:row;grid-gap:32px;gap:32px;justify-content:center}.image-area img{width:500px}@media (max-width: 1100px){.image-area img{width:400px}}

.btn{display:inline-block;margin-bottom:0;font-weight:400;text-align:center;white-space:nowrap;vertical-align:middle;touch-action:manipulation;cursor:pointer;background-image:none;border:1px solid transparent;padding:6px 12px;font-size:14px;line-height:1.6;border-radius:4px;-webkit-user-select:none;-ms-user-select:none;user-select:none;text-decoration:none}.btn-singularit{background:#f07d27;color:#fff;font-weight:700}.btn-singularit:hover{background-color:#d5640f;transition-duration:0.5s;color:#fff}body:hover{-webkit-animation:rotate 1000s linear 0s infinite;animation:rotate 1000s linear 0s infinite}.btn-outline-singularit{color:#f07d27;background-color:#fff;font-weight:700;border:3px solid #f07d27;display:inline}.btn-outline-singularit:hover{color:#d5640f;border-color:#d5640f;transition-duration:0.5s;text-decoration:underline}h1,h2,h3,h4,h5,h6{color:#000;font-family:Raleway thin, sans-serif;font-weight:700;line-height:150%}h1,h2{font-size:2.6rem;letter-spacing:0.14rem;margin-bottom:2rem;font-weight:100}@media (max-width: 800px){h1,h2{font-size:1.8em}}h3{font-size:2rem;font-weight:100}p{line-height:160%;font-size:1.2em}.container{padding-right:35px;padding-left:35px;margin-right:auto;margin-left:auto;max-width:1170px}.footer{margin-top:32px;background-color:#f07d27;color:white;padding:0 32px 16px;font-size:17px}.footer h5{color:white;margin:20px 0 0 0}.footer .long-text{font-size:15px;font-weight:normal;max-width:600px;letter-spacing:0.2px;margin:10px 0}.footer .copyright{font-weight:bolder}

