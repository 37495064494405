@import "../../public/styles/singular.scss";

.footer {
  margin-top: 32px;
  background-color: $color-orange;
  color: white;
  padding: 0 32px 16px;
  font-size: 17px;

  h5 {
    color: white;
    margin: 20px 0 0 0;
  }

  .long-text {
    font-size: 15px;
    font-weight: normal;
    max-width: 600px;
    letter-spacing: 0.2px;
    margin: 10px 0;
  }

  .copyright {
    font-weight: bolder;
  }
}
