form {
  width: 300px;
  margin-top: 32px;

  #size-slider {
    font-size: 1.2em;

    .size-value {
      font-weight: bold;
    }
  }

  .MuiTypography-body1 {
    font-family: inherit;
  }

  .element {
    margin-bottom: 16px;
  }

  .submit-btn {
    margin-top: 32px;
  }
}
