.image-area {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 32px;
  justify-content: center;

  img {
    width: 500px;

    @media (max-width: 1100px) {
      width: 400px;
    }
  }
}
